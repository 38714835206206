import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Footer from "../Footer";
import SEO from "../SEO";

const Layout = ({
  pageTheme,
  pageColor,
  backLink,
  children,
  simple,
  seoTitle,
  seoDescription,
  location
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty("--page-color", pageColor);
  }, [pageColor]);

  const themeClass = pageTheme === "dark" ? "text-white" : "text-black";

  return (
    <div
      className={`${themeClass} font-sans text-base leading-extra-tight overflow-hidden`}
    >
      <SEO title={seoTitle} description={seoDescription} location={location} />
      {!simple && <Header pageTheme={pageTheme} backLink={backLink} />}
      <div className="px-ogs">{children}</div>
      {!simple && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
